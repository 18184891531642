// extracted by mini-css-extract-plugin
export var addButton = "BlogPreview-module--addButton--f221c";
export var addButtonSimple = "BlogPreview-module--addButtonSimple--1cb8c";
export var blogPreviewContainer = "BlogPreview-module--blogPreviewContainer--d184d";
export var buttonText = "BlogPreview-module--buttonText--3767f";
export var buttonTextLight = "BlogPreview-module--buttonTextLight--1c881";
export var buttonTextLightSameLine = "BlogPreview-module--buttonTextLightSameLine--2b55c";
export var buttonTextSameLine = "BlogPreview-module--buttonTextSameLine--ff997";
export var buyButtonCart = "BlogPreview-module--buyButtonCart--2b74f";
export var card = "BlogPreview-module--card--aa403";
export var cardBody = "BlogPreview-module--cardBody--dd44c";
export var cardButton = "BlogPreview-module--cardButton--12751";
export var cardCol = "BlogPreview-module--cardCol--ccc18";
export var cardImg = "BlogPreview-module--cardImg--15755";
export var cardText = "BlogPreview-module--cardText--9fd90";
export var heroButton = "BlogPreview-module--heroButton--b47c6";
export var linkWithoutUnderline = "BlogPreview-module--linkWithoutUnderline--e0cd7";
export var pad = "BlogPreview-module--pad--64075";
export var subtractButton = "BlogPreview-module--subtractButton--45895";