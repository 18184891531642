import { Link } from 'gatsby';
import React from 'react';
import { Container, Accordion } from 'react-bootstrap';
import useLanguageStore from '../LanguageStore/LanguageStore';
import {
  title,
  faqContainer,
  pad,
  accordionItem,
  accordionHeader,
} from './FAQ.module.scss';

type LanguageContent = {
  header: string;
  q1Title: string;
  q1Body: string;
  q1Body2: string;
  q1Link: string;
  q2Title: string;
  q2Body: string;
  q3Title: string;
  q3Body: string;
  q4Title: string;
  q4Body: string;
  q5Title: string;
  q5Body: string;
  q6Title: string;
  q6Body: string;
};

const content: { [name: string]: LanguageContent } = {};
content.English = {
  header: 'Frequently asked questions',
  q1Title: 'How does the compare service work?',
  q1Body:
    'Upload your invoice or answer a few simple questions about your company and your current payment solution.',
  q1Body2: 'Have a look at the ',
  q1Link: 'How it works page.',

  q2Title: 'How quick is it to change?',
  q2Body:
    'It only take a minute to compare the suppliers, the supplier you choose will contact you within 24hours.',

  q3Title: 'Is there any fees?',
  q3Body: 'This service is free of charge! Start saving today!',

  q4Title: 'What happens after I have chosen the best offer?',
  q4Body: 'You will be contacted within 24 hours to complete the process.',

  q5Title: 'What are the requirements for a Terminal?',
  q5Body:
    'In this page you can find all requirements needed to get a terminal.',

  q6Title: 'How do I choose terminal?',
  q6Body: 'Read this guide to find out which terminal is best for you.',
};
content.Español = {
  header: 'Preguntas frecuentes',
  q1Title: '¿Cómo funciona el servicio de comparación?',
  q1Body:
    'Responde algunas preguntas simples sobre tu empresa y preferencias de terminal.',
  q1Body2: 'Mira la página ',
  q1Link: 'Cómo Funciona',

  q2Title: '¿Qué tan rápido es comprar un terminal?',
  q2Body: 'Serás contactado en menos que 24 horas para terminar el proceso.',

  q3Title: '¿Hay algún costo?',
  q3Body: 'Este servicio es completamente gratis. ¡Empieza a ahorrar hoy!',

  q4Title: '¿Qué pasa cuando he elegido un terminal de pago?',
  q4Body:
    'Nosotros te ayudamos durante todo el proceso de obtención. Después de elegir una solución de pago, obtendrás todos los detalles de tu contrato te llamamos para dar seguimiento.',

  q5Title: '¿Cuáles son los requisitos para contratar una terminal bancaria?',
  q5Body:
    'En esta página puedes leer sobre todo lo que necesitas para contratar una terminal.',

  q6Title: '¿Cómo elijo terminal?',
  q6Body: 'Lee nuestra guía para saber cuál terminal es mejor para ti.',
};

const FAQ = (props) => {
  const language = useLanguageStore((state) => state.language);
  let choosenLanguage: LanguageContent;

  language === 'Español'
    ? (choosenLanguage = content.Español)
    : (choosenLanguage = content.English);

  return (
    <div className={faqContainer}>
      <div className={pad} id="FAQ" />
      <Container>
        <div>
          <h2 className={title}>{choosenLanguage.header}</h2>
        </div>
        {/* <Accordion flush className={accordion}> */}
        <Accordion flush>
          <Accordion.Item eventKey="0" className={accordionItem}>
            <Accordion.Header className={accordionHeader}>
              {choosenLanguage.q1Title}
            </Accordion.Header>
            <Accordion.Body>
              {choosenLanguage.q1Body} <br />
              <br />
              {choosenLanguage.q1Body2}{' '}
              <Link to="">{choosenLanguage.q1Link}</Link>{' '}
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="1" className={accordionItem}>
            <Accordion.Header>{choosenLanguage.q2Title}</Accordion.Header>
            <Accordion.Body>{choosenLanguage.q2Body}</Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="2" className={accordionItem}>
            <Accordion.Header>{choosenLanguage.q3Title}</Accordion.Header>
            <Accordion.Body>{choosenLanguage.q3Body}</Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="3" className={accordionItem}>
            <Accordion.Header>{choosenLanguage.q4Title}</Accordion.Header>
            <Accordion.Body>{choosenLanguage.q4Body}</Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="4" className={accordionItem}>
            <Accordion.Header>{choosenLanguage.q5Title}</Accordion.Header>
            <Accordion.Body>
              <Link to="/blog/terminal-de-pago">{choosenLanguage.q5Body}</Link>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="5" className={accordionItem}>
            <Accordion.Header>{choosenLanguage.q6Title}</Accordion.Header>
            <Accordion.Body>
              <Link to="/blog/requisitos-terminal-punto-de-venta">
                {choosenLanguage.q6Body}
              </Link>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </Container>
      <div className={pad} />
    </div>
  );
};

export default FAQ;
