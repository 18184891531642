import React from 'react';
import { Container, Col, Row, Image, Button, Card } from 'react-bootstrap';
import { Link } from 'gatsby';
import TerminalPuntoDeVenta from '../../../content/assets/terminal-punto-de-venta-min.jpg';
import SATTerminalImage from '../../../content/assets/sat-para-terminales-bancarias.jpg';
import bancariasvsmovilesImage from '../../../content/assets/terminales-bancarias-vs-terminales-móviles.jpg';
import {
  pad,
  cardImg,
  cardCol,
  card,
  cardBody,
  cardText,
  cardButton,
  blogPreviewContainer,
  linkWithoutUnderline,
} from './BlogPreview.module.scss';
import useLanguageStore from '../LanguageStore/LanguageStore';

<style>
  @import
  url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@1,100&display=swap');
</style>;

type LanguageContent = {
  title: string;
  buttonText: string;
};

const content: { [name: string]: LanguageContent } = {};
content.English = {
  title: 'Read our guides',
  buttonText: 'Read article',
};
content.Español = {
  title: 'Lee nuestras guías',
  buttonText: 'Lee artículo',
};

const BlogPreview = () => {
  const language = useLanguageStore((state) => state.language);
  let choosenLanguage: LanguageContent;

  language === 'Español'
    ? (choosenLanguage = content.Español)
    : (choosenLanguage = content.English);

  return (
    <div className={blogPreviewContainer}>
      <div style="height: 150px; overflow: hidden;">
        <svg
          viewBox="0 0 500 150"
          preserveAspectRatio="none"
          style="height: 100%; width: 100%;"
        >
          <path
            d="M0.00,49.98 C150.00,150.00 271.49,-49.98 500.00,49.98 L500.00,0.00 L0.00,0.00 Z"
            style="stroke: none; fill:#d7e5f0;"
          />
        </svg>
      </div>
      <a href="/blog" className={linkWithoutUnderline}>
        <h1>{choosenLanguage.title}</h1>
      </a>
      <Container>
        <Row>
          <Col lg="4" md="6" sm="12" className={cardCol}>
            <a
              href="/blog/las-mejores-9-terminales-de-pago-de-mexico"
              className={linkWithoutUnderline}
            >
              <Card className={card}>
                <Card.Img
                  variant="top"
                  className={cardImg}
                  src={TerminalPuntoDeVenta}
                  alt="Comprando con tarjeta credito en terminal bancaria"
                  title="Comprando con tarjeta credito en terminal bancaria"
                />
                <Card.Body className={cardBody}>
                  <Card.Title>
                    Descubre las mejores 9 terminales de pago y cuánto cuestan
                    México
                  </Card.Title>
                  <Card.Text className={cardText}>
                    En este artículo, nos adentraremos en el concepto de las
                    terminales punto de venta, analizaremos los distintos tipos
                    disponibles en el mercado y, lo más relevante, presentaremos
                    una selección de las 9 mejores terminales de pago en México
                    […]
                  </Card.Text>
                  <div className={cardButton}>
                    <Button variant="primary">
                      {choosenLanguage.buttonText}
                    </Button>
                  </div>
                </Card.Body>
              </Card>
            </a>
          </Col>
          <Col lg="4" md="6" sm="12" className={cardCol}>
            <a
              href="/blog/sat-de-las-terminales-punto-de-venta"
              className={linkWithoutUnderline}
            >
              <Card className={card}>
                <Card.Img
                  variant="top"
                  className={cardImg}
                  src={SATTerminalImage}
                  alt="SAT Terminal Punto De Venta"
                  title="SAT para el uso de terminales punto de venta
                  en México"
                />
                <Card.Body className={cardBody}>
                  <Card.Title>
                    Implicaciones del SAT para el uso de terminales punto de
                    venta en México
                  </Card.Title>
                  <Card.Text className={cardText}>
                    SAT de las terminales punto de venta es un tema crucial para
                    las PyMES en México que desean estar al día con las
                    regulaciones fiscales. Con este artículo te brindaremos una
                    guía detallada sobre los requisitos necesarios […]
                  </Card.Text>
                  <div className={cardButton}>
                    <Button variant="primary">
                      {choosenLanguage.buttonText}
                    </Button>
                  </div>
                </Card.Body>
              </Card>
            </a>
          </Col>
          <Col lg="4" md="6" sm="12" className={cardCol}>
            <a
              href="/blog/terminales-bancarias-vs-terminales-moviles"
              className={linkWithoutUnderline}
            >
              <Card className={card}>
                <Card.Img
                  variant="top"
                  className={cardImg}
                  src={bancariasvsmovilesImage}
                  alt="Comparar Terminal Bancaria y Terminal Movil"
                  title="Comparar Terminal Bancaria y Terminal Movil"
                />
                <Card.Body className={cardBody}>
                  <Card.Title>
                    Terminal Bancario (POS/TPV) vs Lectores de Tarjeta
                    (mPOS/Terminal Móvil)
                  </Card.Title>
                  <Card.Text className={cardText}>
                    ¿Quieres un sistema para empezar a aceptar tarjetas en tu
                    negocio, pero no sabes cuál adquirir? Tranquilo, aquí te
                    hablaremos de las principales diferencias entre un Terminal
                    Bancario y un Lector de Tarjetas. […]
                  </Card.Text>
                  <div className={cardButton}>
                    <Button variant="primary">
                      {choosenLanguage.buttonText}
                    </Button>
                  </div>
                </Card.Body>
              </Card>
            </a>
          </Col>
        </Row>
      </Container>
      <div className={pad} />
      <div style="height: 150px; overflow: hidden;">
        <svg
          viewBox="0 0 500 150"
          preserveAspectRatio="none"
          style="height: 100%; width: 100%;"
        >
          <path
            d="M0.00,49.98 C150.00,150.00 349.20,-49.98 500.00,49.98 L500.00,150.00 L0.00,150.00 Z"
            style="stroke: none; fill:rgb(255, 255, 255);"
          />
        </svg>
      </div>
    </div>
  );
};

export default BlogPreview;
